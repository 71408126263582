import { ComponentType, lazy, ReactElement, Suspense, useEffect } from 'react';
import { Amplify, Hub } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { navigateToSignInReturnUrlPath } from './core/services/auth-service';
import { applyTheme, Theme } from '@awsui/components-react/theming';
import { colorChartsOrange400, colorChartsOrange600 } from '@awsui/design-tokens';
import './core/styles/base.scss';
import { LoadingSpinner } from './core/components/loading-spinner';

const AuthenticatedApp = lazy(async (): Promise<{ default: ComponentType }> => import('./authenticated-app'));
const SignIn = lazy(async (): Promise<{ default: ComponentType }> => import('./sign-in/sign-in'));

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: '',
    mandatorySignIn: true,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT,
      responseType: process.env.REACT_APP_OAUTH_RESPONSE_TYPE,
      label: 'Amazon Federate',
      provider: process.env.REACT_APP_OAUTH_PROVIDER
    }
  }
});

const applyGreenLightTheme = (): void => {
  const theme: Theme = {
    tokens: {
      colorBackgroundButtonPrimaryActive: colorChartsOrange400,
      colorBackgroundButtonPrimaryDefault: colorChartsOrange400,
      colorBackgroundButtonPrimaryHover: colorChartsOrange600
    }
  };
  applyTheme({ theme });
};

const GreenLight = (): ReactElement => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  return (
    <Suspense fallback={<LoadingSpinner />}>
      {authStatus === 'authenticated' ? <AuthenticatedApp /> : <SignIn />}
    </Suspense>
  );
};

export const App = (): ReactElement => {
  applyGreenLightTheme();
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      if (event === 'signIn') {
        navigateToSignInReturnUrlPath();
      }
    });
  }, []);
  return (
    <Authenticator.Provider>
      <GreenLight />
    </Authenticator.Provider>
  );
};
